import moment from 'moment'
import { EventSourcePolyfill } from 'ng-event-source';

export function getBSColor(item) {
  /*
  From kermit code:
  JobPushed            = "PUSHED"
  JobDone              = "DONE"
  JobFailed            = "FAILED"
  JobIrrecoverable     = "IRRECOVERABLE"
  JobInProgress        = "IN_PROGRESS"
  JobUnknown           = "UNKNOWN_ID"
  JobCanceled          = "CANCELED"
  */

  var kvStatus = {
    "DONE": "success",
    "FAILED": "danger",
    "IRRECOVERABLE": "danger",
    "PENDING": "info",
    "IN_PROGRESS": "primary",
    "UNKNOWN_ID": "danger",
    "CANCELED": "warning",
    "CANCELING": "warning",
    "PUSHED": "secondary"
  };

  return kvStatus[item.Status];
}

export function defaultVal(val, def) {
  if (isUndef(val)) {
    return def
  }
  return val
}

export function isUndef(value) {
  return (typeof value === "undefined")
}

export function hasValue(value) {
  return (!isUndef(value) && value !== null && value !== '')
}

export function fetchErrorHandler(err) {

  if (err.message && err.message.includes("abort")) {
    // if it is an aborted fetch, ignore
    return
  }
  console.error('Uh oh, an error!', err);
}

export function okToAddToPagination(selectedPage, index, maxIndex) {
  if (index === 0 || index === (maxIndex - 1)) {
    return true
  }
  if (Math.abs(selectedPage - index) < 3) {
    return true
  }
  return false
}

export function getTimeForm(input) {
  if (0 === input) {
    return "NA";
  }
  var date = new Date(0);
  date.setUTCSeconds(input);
  return moment(date).format('D MMM YYYY, H:mm:ss');
}

export function getDuration(item, currentTime) {
  if (isUndef(currentTime)) {
    currentTime = (new Date).getTime()
  }
  var stopTime = new Date(0);
  stopTime.setUTCSeconds(item.StopTime);
  var startTime = new Date(0);
  startTime.setUTCSeconds(item.StartTime);
  if (item.StartTime === 0) {
    return ""
  } else if (item.StopTime === 0) {
    return moment().startOf('day').seconds((currentTime - startTime) / 1000).format('H:mm:ss');
  }
  var dur = (stopTime - startTime) / 1000;
  if (dur < 0) {
    return "?"
  }
  return moment().startOf('day').seconds(dur).format('H:mm:ss');
}

export function timeFromNow(ts) {
  return moment(ts).fromNow()
}

export function getBearerToken() {
  const bearerToken = localStorage.getItem('bearer_token');
  if (!bearerToken) {
    throw new Error('No Bearer Token found');
  }
  return bearerToken;
}


export function fetchFromApi(urlSuffix, signal) {
  return fetch(`https://kermit-api.staging.cp.kpn-dsh.com/` + urlSuffix, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    },
    credentials: "same-origin",
    signal: signal
  }).then(function (response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }).then(result => {
    return result.json();
  }).catch(function (error) {
    console.log(error);
  });
}

export function createEventSource(urlSuffix) {
  var headers = {
    'Authorization': `Bearer ${getBearerToken()}`
  };
  declare var EventSourcePolyfill: any;
  var options = {
    headers: headers
  };
  let eventSource = new EventSourcePolyfill(`https://kermit-api.staging.cp.kpn-dsh.com/events/${urlSuffix}`, options);
  eventSource.onerror = (e) => {
    fetchErrorHandler(e);
  }
  return eventSource
}

export function postToApi(urlSuffix, payload) {
  return fetch('https://kermit-api.staging.cp.kpn-dsh.com/' + urlSuffix, {
    credentials: "same-origin",
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    },
    body: JSON.stringify(payload)
  }).then(result => {
    return result.json();
  });
}
